<template>
  <div class="ml-4">
    <a
      class="pl-2"
      v-for="(item, index) in reviewTabList"
      :key="index"
      :ripple="true"
      @click="item.url ? (currentTab = item.url) : 1"
      style="background-color: transparent; width: auto"
      :elevation="0"
      v-if="item.display"
    >
      <span
        v-if="item.url"
        style="font-size: 13px"
        class="pa-0"
        :class="{
          'active-tab': currentTab == item.url,
          'un-active-tab': currentTab != item.url,
        }"
        >{{ item.title }}</span
      >
      <span
        v-if="!item.url"
        style="border-left: 2px solid #7a7a7a"
        class="mx-1"
      ></span>
    </a>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "AppBarReview",
  props: {},
  components: {},
  data() {
    return {
      currentTab: "todaySuggestion",
      reviewTabList: this.appStore.reviewTabList,
    };
  },
  mounted() {
    this.reviewTabList = [
      {
        title: "雷达",
        url: "todaySuggestion",
        display: true,
      },
      {
        title: "涨跌幅",
        url: "singleStocks",
        display: true,
      },
      {
        title: "分类统计",
        url: "industryAppliesOrderByMarketValue",
        display: true,
      },
      // {
      // 	title:'行业',
      // 	url:'industryApplies',
      // },

      {
        title: "赛道标签",
        url: "tagApplies",
        display: true,
      },
      {
        title: "比较分析",
        url: "reviewMarkets",
        display: true,
      },

      {
        title: "|",
        url: "",
        display: true,
      },

      {
        title: "全A细分",
        url: "reviewIndustryTapStock",
        display: true,
      },
      {
        title: "全A个股",
        url: "reviewCnSingleStock",
        display: true,
      },
      {
        title: "全A行业",
        url: "reviewCnIndustryStock",
        display: true,
      },

      {
        title: "|",

        url: "",
        display: true,
      },

      {
        title: "周会",
        url: "weeklyMeeting",
        display: true,
      },
      {
        title: "每日复盘",
        url: "dayReview",
        display: this.$commonHelpers.getPermissions("review.dayReview"),
      },
      {
        title: "每周复盘",
        url: "weekReview",
        display: this.$commonHelpers.getPermissions("review.dayReview"),
      },
    ];
    this.appStore.reviewTabList = this.reviewTabList;
    //console.log(this.$commonHelpers.getPermissions('review.dayReview'));
    Bus.$on("AppBarReviewChange", (e) => {
      this.currentTab = e;
    });
  },
  methods: {},
  watch: {
    currentTab(val) {
      Bus.$emit("reviewTabChange", val);
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
}
.un-active-tab {
  color: #7e7e7e;
}
</style>
